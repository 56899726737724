import { TopicSelector } from 'Shared/topic_selector';
import WorkbenchManager from 'Shared/workbench_manager';

ContentDropdownGroup($$('.workbench-topic-variant-dropdown'));

$$('#generic-save-topic-button').addEvent('click', function () {
   let variantid = this.get('data-variantid');
   let wikiid = this.get('data-wikiid');
   let button = this;

   if (this.hasClass('checked')) {
      TopicSelector.deselect(button, variantid, wikiid, /* useIndicator */ true);
   } else {
      TopicSelector.select(button, variantid, wikiid, /* useIndicator */ true);
   }
});

$$('.js-workbench-checkbox').addEvent('click', function (e) {
   const ADD_TO_WORKBENCH = 'add';
   const REMOVE_FROM_WORKBENCH = 'remove';

   let action = this.get('checked') ? ADD_TO_WORKBENCH : REMOVE_FROM_WORKBENCH;
   let variantid = this.get('data-topic-variantid');
   let wikiid = this.get('data-wikiid');

   if (action == ADD_TO_WORKBENCH) {
      $('topic-generic').set('checked', !variantid);
      WorkbenchManager.addItem(variantid, wikiid, /* useIndicator */ true);
   } else {
      WorkbenchManager.removeItem(variantid, wikiid, /* useIndicator */ true);
   }

   TopicSelector.updateVariantDropdownState();
});
